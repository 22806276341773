import {
  Circle,
  CreditCard,
  FilePlus,
  FileText,
  Folder,
  MessageCircle,
  Pocket,
  Settings,
  User
} from 'react-feather'

export default [
  {
    id: 'appointments',
    title: 'Appointments',
    icon: <FileText size={20} />,
    navLink: '/appointments'
  },

  {
    id: 'documents',
    title: 'Documents',
    icon: <Folder size={20} />,
    children: [
      {
        id: 'documents',
        title: 'Documents',
        icon: <Folder size={12} />,
        navLink: '/documents/document'
      },
      {
        id: 'Saving',
        title: 'Saving Payment Info',
        icon: <Circle size={12} />,
        navLink: '/documents/SavingPayment'
      }
    ]
  },
  {
    id: 'messages',
    title: 'Messages ....',
    icon: <MessageCircle size={20} />,
    navLink: '/messages'
  },
  {
    id: 'billing',
    title: 'Billing',
    icon: <FilePlus size={20} />,
    children: [
      {
        id: 'billing/bills',
        title: 'Bills',
        icon: <CreditCard size={12} />,
        navLink: '/billing/bills'
      },
      {
        id: 'Insurance',
        title: 'Insurance',
        icon: <Pocket size={12} />,
        navLink: '/billing/insurance'
      }
    ]
  },
  {
    id: 'my-Provider',
    title: 'My Provider',
    icon: <User size={20} />,
    navLink: '/my-provider'
  }
  // {
  //   id: 'settings',
  //   title: 'Settings',
  //   icon: <Settings size={20} />,
  //   navLink: '/settings'
  // }
]
