import { createSlice } from '@reduxjs/toolkit'
import { getAllBillingAction } from './billingAction'

export const billingReducer = createSlice({
  name: 'billing',
  initialState: {
    error: null,
    loading: false,
    getAllBillings: {
      data: [],
      offset: 0,
      limit: 10,
      count: 0
    }
  },
  reducers: {
    resetBillingList: (state) => {
      state.getAllBillings = {
        data: [],
        offset: 0,
        limit: 10,
        total: 0
      }
    }
  },

  extraReducers: (builder) => {
    builder

      // ** Get my provider
      .addCase(getAllBillingAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllBillingAction.fulfilled, (state, action) => {
        state.loading = false
        state.getAllBillings.data = action.payload?.result
        state.getAllBillings.offset = action.payload?.offset
        state.getAllBillings.limit = action.payload?.limit
        state.getAllBillings.count = action.payload?.count
        state.error = null
      })
      .addCase(getAllBillingAction.rejected, (state, action) => {
        state.loading = false
        state.getAllBilling = {}
        state.error = action.payload
      })
  }
})

export const { resetBillingList } = billingReducer.actions
export default billingReducer.reducer
