import { createSlice } from '@reduxjs/toolkit'
import {
  checkInAppointmentsAction,
  getAllAppointmentsAction,
  getPrevAndNextAppointmentsAction
} from './appointmentAction'

export const AppointmentReducer = createSlice({
  name: 'appointment',
  initialState: {
    error: null,
    loading: false,
    getLoading: false,
    getAllAppointments: {
      data: [],
      offset: 0,
      limit: 10,
      count: 0
    },
    checkInLoading: false,
    checkInAppointments: null,
    getPrevAndNextAppointments: null
  },
  reducers: {},

  extraReducers: (builder) => {
    builder

      // ** get all appointments
      .addCase(getAllAppointmentsAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllAppointmentsAction.fulfilled, (state, action) => {
        state.loading = false
        state.getAllAppointments.data = action.payload?.result
        state.getAllAppointments.offset = action.payload?.offset
        state.getAllAppointments.limit = action.payload?.limit
        state.getAllAppointments.count = action.payload?.count
        state.error = null
      })
      .addCase(getAllAppointmentsAction.rejected, (state, action) => {
        state.loading = false
        state.getAllAppointments = []
        state.error = action.payload
      })

      // ** get previous and next appointments
      .addCase(getPrevAndNextAppointmentsAction.pending, (state) => {
        state.getLoading = true
      })
      .addCase(getPrevAndNextAppointmentsAction.fulfilled, (state, action) => {
        state.getLoading = false
        state.getPrevAndNextAppointments = action.payload
        state.error = null
      })
      .addCase(getPrevAndNextAppointmentsAction.rejected, (state, action) => {
        state.getLoading = false
        state.getPrevAndNextAppointments = {}
        state.error = action.payload
      })

      // ** check In  appointments
      .addCase(checkInAppointmentsAction.pending, (state) => {
        state.checkInLoading = true
      })
      .addCase(checkInAppointmentsAction.fulfilled, (state, action) => {
        state.checkInLoading = false
        state.checkInAppointments = action.payload
        state.error = null
      })
      .addCase(checkInAppointmentsAction.rejected, (state, action) => {
        state.checkInLoading = false
        state.checkInAppointments = {}
        state.error = action.payload
      })
  }
})

export default AppointmentReducer.reducer
