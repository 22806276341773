/* eslint-disable no-duplicate-imports */
// ** Redux Imports
import { configureStore } from '@reduxjs/toolkit'
import AppointmentReducer from './appointment/appointmentSlice'
import AuthReducer from './authentication/authSlice'
import billingReducer from './billing/billingSlice'
import documentReducer from './document/documentsSlice'
import layout from './layout'
import messagesReducer from './messages/messageSlice'
import myProviderReducer from './myProvider/myProviderSlice'
import navbar from './navbar'
import notificationsReducer from './notification/notificationSlice'
import myProfileReducer from './profile/profileSlice'
import skinReducer from './skin'
import stripeReducer from './stripe/stripeSlice'
const store = configureStore({
  reducer: {
    navbar,
    layout,
    skin: skinReducer,
    auth: AuthReducer,
    provider: myProviderReducer,
    billing: billingReducer,
    myProfile: myProfileReducer,
    messages: messagesReducer,
    appointment: AppointmentReducer,
    notification: notificationsReducer,
    stripe: stripeReducer,
    document: documentReducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

export { store }
