// ** Dropdowns Imports
import { Moon, Sun } from 'react-feather'
import NotificationDropdown from './NotificationDropdown'
import UserDropdown from './UserDropdown'
import { NavItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'

const NavbarUser = (props) => {
  // const dispatch = useDispatch()
  const { skin, setSkin } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className="ficon" onClick={() => setSkin('light')} />
    } else {
      return (
        <Moon
          className="ficon"
          color="#4b4b4b"
          onClick={() => setSkin('dark')}
        />
      )
    }
  }

  return (
    <ul className="nav navbar-nav align-items-center ">
      <NavItem className="d-lg-block">
        <NavLink className="nav-link-style">
          <ThemeToggler />
        </NavLink>
      </NavItem>
      <NotificationDropdown />
      {/* <NavItem className="d-lg-block d-none">
        <NavLink
          tag={Link}
          to="/login"
          onClick={() => dispatch(handleLogout())}
        >
          <h5 className="fw-800 mt-1">Logout</h5>
        </NavLink>
      </NavItem> */}

      <UserDropdown />
    </ul>
  )
}
export default NavbarUser
