import { MAIN_URL } from '@src/constants'
// ** Auth Endpoints
export default {
  loginEndpoint: `${MAIN_URL}/api/login`,
  forgotPasswordEndpoint: `${MAIN_URL}/api/reset-password`,
  ResetPasswordEndpoint: `${MAIN_URL}/api/reset-password-confirm`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  typeBearer: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',

  //** Documents */
  getAllClientDocumentsEndPoint: `${MAIN_URL}/api/client-documents`,

  //** My provider */
  getMyProviderEndPoint: `${MAIN_URL}/api/clients/my-provider`,

  //** Billing */
  getAllBillingEndPoint: `${MAIN_URL}/api/client-invoices`,

  //** My profile */
  getMyProfileEndPoint: `${MAIN_URL}/api/clients/me`,
  updateMyProfileEndPoint: `${MAIN_URL}/api/clients/`,

  //** Messages */
  sendMessageEndpoint: `${MAIN_URL}/api/messages`,

  //** Appointments */
  getAllAppointmentsEndpoint: `${MAIN_URL}/api/calendar-list`,
  getPrevAndNextAppointmentsEndpoint: `${MAIN_URL}/api/previous-and-next`,
  checkInAppointmentsActionEndpoint: `${MAIN_URL}/api/appointment`,

  //** Notifications */
  getAllNotificationsEndPoint: `${MAIN_URL}/api/client-notification`,
  markAllNotificationEndPoint: `${MAIN_URL}/api/client-notification/mark-all`,

  //** Stripe */
  getConnectedAccountEndPoint: `${MAIN_URL}/api/get-provider-account-id`,
  getAllStripeCardEndPoint: `${MAIN_URL}/api/client-stripe`,
  registerStripeCardEndPoint: `${MAIN_URL}/api/card`,
  registerMoreStripeCardEndPoint: `${MAIN_URL}/api/card`,
  updateStripeCardEndPoint: `${MAIN_URL}/api/client-stripe/change-default`,
  payClientInvoiceStripeCardEndPoint: `${MAIN_URL}/api/client-invoices/`
}
