/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { X } from 'react-feather'
import toast from 'react-hot-toast'

// ** Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name="Check-in Error"
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.msg ||
            errors?.detail ||
            errors[key] ||
            errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

//** Get all appointments  */
export const getAllAppointmentsAction = createAsyncThunk(
  'appointment/getAllAppointments',
  async ({ offset, limit }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getAllAppointments(offset, limit)
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** Get previous and next appointment */
export const getPrevAndNextAppointmentsAction = createAsyncThunk(
  'appointment/getPrevAndNextAppointments',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await useJwt.getPrevAndNextAppointments()
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** check in appointment */
export const checkInAppointmentsAction = createAsyncThunk(
  'appointment/checkInAppointments',
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await useJwt.checkInAppointmentsAction(id, data)
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Handling Pagination
export const handlePageChange = createAsyncThunk(
  'billing/handlePageChange',
  async ({ offset, limit }, { dispatch }) => {
    dispatch(
      getAllAppointmentsAction({
        offset,
        limit
      })
    )
  }
)

// ** Handling Limit
export const handleLimitChange = createAsyncThunk(
  'billing/handleLimitChange',
  async ({ oldLimit, newLimit }, { dispatch }) => {
    if (newLimit !== oldLimit) {
      dispatch(
        getAllAppointmentsAction({
          offset: 0,
          limit: newLimit
        })
      )
    }
  }
)
