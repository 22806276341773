/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** components
import { ToastContent } from '@src/components/toast'
import { X } from 'react-feather'
import toast from 'react-hot-toast'

// Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name={key}
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.detail || errors[key][0] || errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

//** Get All Notifications */
export const getAllNotificationAction = createAsyncThunk(
  'notifications/getAllNotifications',
  async ({ offset, limit, search }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getAllNotifications(offset, limit, search)
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Mark All  Notifications
export const markAllNotificationAction = createAsyncThunk(
  'notifications/markAllNotification',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.markAllNotification()
      dispatch(getAllNotificationAction({ offset: 0, limit: 10 }))
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)
