/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  deleteClientDocumentsAction,
  getAllClientDocumentsAction,
  getClientDocumentsAction,
  registerClientDocumentsAction,
  updateClientDocumentsAction
} from './documentsAction'

export const documentReducer = createSlice({
  name: 'document',
  initialState: {
    error: null,
    loading: false,
    registerLoading: false,
    getAllDocuments: {
      data: [],
      offset: 0,
      limit: 10,
      count: 0
    },
    registerDocuments: null,
    deleteDocumentLoading: false,
    updateLoading: false,
    updateDocuments: null,
    getDocuments: null,
    getLoading: false
  },
  reducers: {
    resetGetDocumentById: (state) => {
      state.getDocuments = null
    }
  },

  extraReducers: (builder) => {
    builder

      // ** get all documents
      .addCase(getAllClientDocumentsAction.pending, (state) => {
        if (state.getAllDocuments.data?.length === 0) {
          state.loading = true
        }
      })
      .addCase(getAllClientDocumentsAction.fulfilled, (state, action) => {
        state.loading = false
        state.getAllDocuments.data = action.payload?.result
        state.getAllDocuments.offset = action.payload?.offset
        state.getAllDocuments.limit = action.payload?.limit
        state.getAllDocuments.count = action.payload?.count
        state.error = null
      })
      .addCase(getAllClientDocumentsAction.rejected, (state, action) => {
        state.loading = false
        state.getAllDocuments = []
        state.error = action.payload
      })

      // ** get documents
      .addCase(getClientDocumentsAction.pending, (state) => {
        state.getLoading = true
      })
      .addCase(getClientDocumentsAction.fulfilled, (state, action) => {
        state.getLoading = false
        state.getDocuments = action.payload
        state.error = null
      })
      .addCase(getClientDocumentsAction.rejected, (state, action) => {
        state.getLoading = false
        state.getDocuments = {}
        state.error = action.payload
      })

      // ** register documents
      .addCase(registerClientDocumentsAction.pending, (state) => {
        state.registerLoading = true
      })
      .addCase(registerClientDocumentsAction.fulfilled, (state, action) => {
        state.registerLoading = false
        state.registerDocuments = action.payload
        state.error = null
      })
      .addCase(registerClientDocumentsAction.rejected, (state, action) => {
        state.registerLoading = false
        state.registerDocuments = {}
        state.error = action.payload
      })

      // ** Update documents
      .addCase(updateClientDocumentsAction.pending, (state) => {
        state.updateLoading = true
      })
      .addCase(updateClientDocumentsAction.fulfilled, (state, action) => {
        state.updateLoading = false
        state.updateDocuments = action.payload
        state.error = null
      })
      .addCase(updateClientDocumentsAction.rejected, (state, action) => {
        state.updateLoading = false
        state.updateDocuments = {}
        state.error = action.payload
      })

      //** Delete Client Document*/
      .addCase(deleteClientDocumentsAction.pending, (state) => {
        state.deleteDocumentLoading = true
      })
      .addCase(deleteClientDocumentsAction.fulfilled, (state, action) => {
        state.deleteDocumentLoading = false
        state.error = null
      })
      .addCase(deleteClientDocumentsAction.rejected, (state, action) => {
        state.deleteDocumentLoading = false
        state.error = action.payload
      })
  }
})

export const { resetGetDocumentById } = documentReducer.actions
export default documentReducer.reducer
