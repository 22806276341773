// ** React Imports
import { NavLink } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { useSelector } from 'react-redux'
import { Badge } from 'reactstrap'

const VerticalNavMenuLink = ({ item, activeItem }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? 'a' : NavLink

  const {
    getAllNotification
    //  loading, markAllLoading
  } = useSelector((state) => state.notification)
  const rows = getAllNotification?.data
  // useEffect(() => {
  //   dispatch(getAllNotificationAction({ offset: 0, limit: 10 }))
  // }, [])

  const unreadNotificationNumber = rows?.filter(
    (item) => item.read === false
  )?.length

  // ** Hooks
  const { t } = useTranslation()

  return (
    <li
      className={classnames({
        'nav-item': !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      <LinkTag
        className="d-flex align-items-center "
        target={item.newTab ? '_blank' : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
              href: item.navLink || '/'
            }
          : {
              to: item.navLink || '/',
              className: ({ isActive }) => {
                if (isActive && !item.disabled) {
                  return 'd-flex align-items-center active'
                }
              }
            })}
        onClick={(e) => {
          if (
            item.navLink.length === 0 ||
            item.navLink === '#' ||
            item.disabled === true
          ) {
            e.preventDefault()
          }
        }}
      >
        {item.icon}
        <span
          className="menu-item text-truncate "
          style={{ marginRight: '.3rem' }}
        >
          {t(item.title)}
        </span>
        {item.id === 'messages' && unreadNotificationNumber > 0 && (
          <Badge className="ms-auto me-1" color={'danger'} pill>
            {unreadNotificationNumber}
          </Badge>
        )}
        {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  )
}

export default VerticalNavMenuLink
