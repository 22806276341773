/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import moment from 'moment-timezone'
import { DefaultRoute } from '../router/routes'

import _ from 'lodash'

export const isObjEmpty = (obj) => Object.keys(obj).length === 0
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')
export const getTime = (data, format = 'LT') =>
  data && moment(data).format(format)
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}
export const calculateYearDifference = (date1) => {

  const dob = new Date(date1)
  const today = new Date()
  let age = today.getFullYear() - dob.getFullYear()

  const birthdayThisYear = new Date(
    today.getFullYear(),
    dob.getMonth(),
    dob.getDate()
  )

  age -= today < birthdayThisYear ? 1 : 0

  return age

}
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const isUserLoggedIn = () => sessionStorage.getItem('userData')
export const getUserData = () => JSON.parse(sessionStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = (role) => {
  if (role) return DefaultRoute
  return '/login'
}

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getUserFullName = (user, defaultName = 'Other') => {
  if (!user) {
    return defaultName
  }
  // const preferredName = user.preferredName ? `"${user.preferredName}"` : ''
  const fullName = `${user.firstName || ''} ${user.middleName || ''} ${
    user.lastName || ''
  }`
    .trim()
    .replace(/ +(?= )/g, '')
  const fullNameRs = _.isEmpty(fullName) ? defaultName : fullName

  return fullNameRs
}

// export const getStatus = (id) => {
//   switch (id) {
//     case 1:
//       return {
//         class: " reqbgColor badge-billing fs-11",
//         status: "Paid"
//       }
//     case 2:
//       return {
//         class: "due-bdColor badge-billing",
//         status: "Due"
//       }
//     case 3:
//       return {
//         class: "pastDue-bgColor badge-billing",
//         status: "Past Due"
//       }

//     default:
//       return {
//         color: "secondary badge-billing",
//         status: "none"
//       }
//   }
// }

export const getStatus = (id) => {
  switch (id) {
    case 0:
      return {
        class: ' PaidBillingColor badge-billing fs-11 paid-btn-padding',
        status: 'Paid'
      }
    case 1:
      return {
        class: 'dueBillingColor badge-billing',
        status: 'Unpaid'
      }
    case 2:
      return {
        class: 'unPaidBillingColor badge-billing paid-btn-padding',
        status: 'Void'
      }

    default:
      return {
        color: 'secondary badge-billing paid-btn-padding',
        status: 'none'
      }
  }
}

// American Phone Number
export const PhoneUS = (value, previousValue) => {
  if (!value) return value
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`
  }
}

export const dateUS = (date) => {
  return moment.unix(`${date}`).tz('America/Los_Angeles').format('MM/DD/YYYY')
}

export const dateUnix = (date) => {
  return moment(`${date}`).tz('America/Los_Angeles').unix()
}

// for patch request
export const getModifiedValues = (values, initialValues) => {
  const modifiedValues = {}

  if (values) {
    Object.entries(values).forEach((entry) => {
      const key = entry[0]
      const value = entry[1]

      if (value !== initialValues[key]) {
        modifiedValues[key] = value
      }
    })
  }

  return modifiedValues
}
