import { createSlice } from '@reduxjs/toolkit'
import { getMyProfileAction, updateMyProfileAction } from './profileAction'

export const myProfileReducer = createSlice({
  name: 'myProfile',
  initialState: {
    error: null,
    loading: false,
    getMyProfile: null,
    updateMyProfile: null,
    updateLoading: false
  },
  reducers: {
    resetGetMyProfile: (state) => {
      state.getMyProfile = null
    }
  },
  extraReducers: (builder) => {
    builder

      // ** Get my Profile
      .addCase(getMyProfileAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getMyProfileAction.fulfilled, (state, action) => {
        state.loading = false
        state.getMyProfile = action.payload
        state.error = null
      })
      .addCase(getMyProfileAction.rejected, (state, action) => {
        state.loading = false
        state.getMyProfile = {}
        state.error = action.payload
      })

      // ** Update my Profile
      .addCase(updateMyProfileAction.pending, (state) => {
        state.updateLoading = true
      })
      .addCase(updateMyProfileAction.fulfilled, (state, action) => {
        state.updateLoading = false
        state.getMyProfile = action.payload
        state.updateMyProfile = action.payload
        state.error = null
      })
      .addCase(updateMyProfileAction.rejected, (state, action) => {
        state.updateLoading = false
        state.updateMyProfile = {}
        state.error = action.payload
      })
  }
})
export const { resetGetMyProfile } = myProfileReducer.actions
export default myProfileReducer.reducer
