// ** React Imports
import { Link } from "react-router-dom"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Third Party Components
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from '@src/assets/default.png'
import { handleLogout } from "../../../../redux/authentication/authSlice"
import { useDispatch, useSelector } from "react-redux"

const UserDropdown = () => {
  const dispatch = useDispatch()
  const avatar = useSelector((state) => state.auth?.user?.avatar)
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex ">
          {/* <h5 className="user-name fw-bold text-dark fw-800 " style={{marginTop:"8px"}} >Logout</h5> */}
          {/* <span className="user-status">Admin</span> */}
        </div>
        <Avatar
          img={avatar ? avatar : defaultAvatar}
          imgHeight="40"
          imgWidth="40"
          imgClassName={'object-fit-cover'}

          // status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/profile">
          <User size={14} className="me-75" />
          <span className="align-middle">Profile</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/login"  onClick={() => dispatch(handleLogout())}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
