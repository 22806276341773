/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'

// ** Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error While Updating Profile"
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.msg ||
            errors?.email ||
            errors?.detail ||
            errors[key] ||
            errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

//** get client profile  */
export const getMyProfileAction = createAsyncThunk(
  'myProfile/getMyProfile',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await useJwt.getMyProfile()
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

//** update client profile  */
export const updateMyProfileAction = createAsyncThunk(
  'myProfile/updateMyProfile',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await useJwt.updateMyProfile(id, data)
      toast((t) => (
        <ToastContent
          t={t}
          name="Profile Updated Successfully"
          icon={<Check size={14} />}
          color="success"
          msg={response.data.msg}
        />
      ))
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)
