/* eslint-disable implicit-arrow-linebreak */
// ** React Imports
import { Fragment, lazy } from 'react'
import { Navigate } from 'react-router-dom'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'

// ** Route Components
import PrivateRoute from '@components/routes/PrivateRoute'
import PublicRoute from '@components/routes/PublicRoute'

// ** Utils

import { isObjEmpty } from '@utils'
// import Started from "../../views/documents/started"
// import SavingPayment from "../../views/documents/savingPayment"
// import Profile from "../../views/profile"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/appointments'

const Login = lazy(() => import('../../views/Login'))
const Register = lazy(() => import('../../views/Register'))
const ForgotPassword = lazy(() => import('../../views/ForgotPassword'))
const ResetPassword = lazy(() =>
  import('../../views/ForgotPassword/reset-password')
)
const PrivacyPolicy = lazy(() => import('../../views/privacyPolicy'))
const TermOfServices = lazy(() => import('../../views/tos'))
const Contact = lazy(() => import('../../views/contact'))

const Error = lazy(() => import('../../views/Error'))
// Pages
const Appointments = lazy(() => import('@src/views/appointments'))
const Documents = lazy(() => import('@src/views/documents/document'))
const Messages = lazy(() => import('@src/views/messages'))
const Billing = lazy(() => import('@src/views/billing'))
const Insurance = lazy(() => import('@src/views/insurance'))
const Provider = lazy(() => import('@src/views/provider'))
const Settings = lazy(() => import('@src/views/settings'))
const Profile = lazy(() => import('@src/views/profile'))
const SavingPayment = lazy(() => import('@src/views/documents/SavingPayment'))
const InvoicePayment = lazy(() => import('@src/views/billing/invoice'))
const CreatePassword = lazy(() => import('../../views/createPassword'))
const ClientDocumentPreview = lazy(() =>
  import('../../views/documents/document/intake-form')
)

const Started = lazy(() => import('@src/views/documents/started'))
// ** Merge Routes
const Routes = [
  {
    path: '/',
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/create-new-password',
    element: <CreatePassword />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  // {
  //   path: '/register',
  //   element: <Register />,
  //   meta: {
  //     layout: 'blank',
  //     publicRoute: true,
  //     restricted: true
  //   }
  // },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  // {
  //   path: '/contact',
  //   element: <Contact />,
  //   meta: {
  //     layout: 'blank',
  //     publicRoute: true,
  //     restricted: true
  //   }
  // },
  // {
  //   path: '/terms-of-service',
  //   element: <TermOfServices />,
  //   meta: {
  //     layout: 'blank',
  //     publicRoute: true,
  //     restricted: true
  //   }
  // },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/*',
    element: <Error />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/appointments',
    element: <Appointments />,
    meta: {
      publicRoute: false
    }
  },
  // {
  //   path: "/documents",
  //   element: <Documents />
  // },
  {
    path: '/messages',
    element: <Messages />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/bills',
    element: <Billing />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/insurance',
    element: <Insurance />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/documents/document',
    element: <Documents />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/documents/document/:id',
    element: <ClientDocumentPreview />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/documents/savingPayment',
    element: <SavingPayment />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/my-provider',
    element: <Provider />,
    meta: {
      publicRoute: false
    }
  },
  {
    path: '/bills/invoice',
    element: <InvoicePayment />,
    meta: {
      publicRoute: false
    }
  },
  // {
  //   path: '/settings',
  //   element: <Settings />,
  //   meta: {
  //     publicRoute: false
  //   }
  // },
  {
    path: '/profile',
    element: <Profile />,
    meta: {
      publicRoute: false
    }
  }
  // {
  //   path: '/documents',
  //   element: <Started />,
  //   meta: {
  //     publicRoute: false
  //   }
  // }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute
        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, Routes, TemplateTitle, getRoutes }
