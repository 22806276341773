import { Icon } from '@iconify/react'

export default [
  {
    id: 'appointments',
    title: 'Appointments',
    icon: <Icon icon="ant-design:file-text-outlined" width="20" height="20" />,
    navLink: '/appointments'
  },
  // {
  //   id: 'documents',
  //   title: 'Documents',
  //   icon: (
  //     <Icon icon="ant-design:folder-open-outlined" width="20" height="20" />
  //   ),
  //   navLink: '/documents'
  // },
  {
    id: 'documents',
    title: 'Documents',
    icon: (
      <Icon icon="ant-design:folder-open-outlined" width="20" height="20" />
    ),
    navLink: '/documents/document'
  },
  {
    id: 'messages',
    title: 'Messages',
    icon: <Icon icon="bx:message-rounded" width="20" height="20" />,
    navLink: '/messages'
  },
  {
    id: 'billing',
    title: 'Billing',
    icon: <Icon icon="tabler:file-dollar" width="20" height="20" />,
    navLink: '/bills'
  },

  {
    id: 'Insurance',
    title: 'Insurance',
    icon: <Icon icon="clarity:shield-check-line" width="20" height="20" />,
    navLink: '/insurance'
  },
  {
    id: 'my-Provider',
    title: 'My Provider',
    icon: <Icon icon="bytesize:user" width="20" height="20" />,
    navLink: '/my-provider'
  }
  // {
  //   id: 'settings',
  //   title: 'Settings',
  //   icon: <Icon icon="ant-design:setting-outlined" width="20" height="20" />,
  //   navLink: '/settings'
  // }
]
