import { createSlice } from '@reduxjs/toolkit'
import { getMyProviderAction } from './myProviderAction'

export const myProviderReducer = createSlice({
  name: 'provider',
  initialState: {
    error: null,
    loading: false,
    getMyProvider: null
  },
  reducers: {
    resetGetProvider: (state) => {
      state.getMyProvider = null
    }
  },

  extraReducers: (builder) => {
    builder

      // ** Get my provider
      .addCase(getMyProviderAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getMyProviderAction.fulfilled, (state, action) => {
        state.loading = false
        state.getMyProvider = action.payload
        state.error = null
      })
      .addCase(getMyProviderAction.rejected, (state, action) => {
        state.loading = false
        state.getMyProvider = {}
        state.error = action.payload
      })
  }
})

export const { resetGetProvider } = myProviderReducer.actions
export default myProviderReducer.reducer
