/* eslint-disable no-unused-vars */
import useJwt from '@src/auth/jwt/useJwt'
import { createAsyncThunk } from '@reduxjs/toolkit'

import toast from 'react-hot-toast'
import { Check, X } from 'react-feather'
import { ToastContent } from '@src/components/toast'

// ** Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error While Updating Invoice"
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.msg ||
            errors?.detail ||
            errors[key] ||
            errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

//** Get provider calendar  */
export const getAllBillingAction = createAsyncThunk(
  'billing/getMyProvider',
  async ({offset, limit}, { rejectWithValue }) => {
    try {
      const response = await useJwt.getAllBilling(offset, limit)
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Handling Pagination
export const handlePageChange = createAsyncThunk(
  'billing/handlePageChange',
  async ({ offset, limit }, { dispatch }) => {
    dispatch(
      getAllBillingAction({
        offset,
        limit
      })
    )
  }
)

// ** Handling Limit
export const handleLimitChange = createAsyncThunk(
  'billing/handleLimitChange',
  async ({ oldLimit, newLimit }, { dispatch }) => {
    if (newLimit !== oldLimit) {
      dispatch(
        getAllBillingAction({
          offset: 0,
          limit: newLimit
        })
      )
    }
  }
)
