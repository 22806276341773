/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import {
  getAllNotificationAction,
  markAllNotificationAction
} from './notificationActions'

export const notificationsReducer = createSlice({
  name: 'notifications',
  initialState: {
    markAllNotification: null,
    getAllNotification: {
      data: [],
      offset: 0,
      limit: 10,
      count: 0
    },
    loading: false,
    markAllLoading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    const success = (state) => {
      state.loading = false
      state.error = null
    }

    const error = (state, action) => {
      state.loading = false
      state.error = action.payload
    }

    builder

      // Get All  Notification
      .addCase(getAllNotificationAction.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllNotificationAction.fulfilled, (state, action) => {
        success(state)
        state.getAllNotification.data = action.payload?.result
        state.getAllNotification.offset = action.payload?.offset
        state.getAllNotification.limit = action.payload?.limit
        state.getAllNotification.count = action.payload?.count
      })
      .addCase(getAllNotificationAction.rejected, (state, action) => {
        error(state, action)
        state.getAllNotification = null
      })
      // Mark All  Notification
      .addCase(markAllNotificationAction.pending, (state) => {
        state.markAllLoading = true
      })
      .addCase(markAllNotificationAction.fulfilled, (state, action) => {
        state.markAllLoading = false
        state.markAllNotification = action.payload
        state.error = null
      })
      .addCase(markAllNotificationAction.rejected, (state, action) => {
        state.markAllLoading = false
        state.getAllNotification = null
        state.error = action.payload
      })
  }
})

export default notificationsReducer.reducer
