/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'

// ** Field Errors
const fieldErrors = (err) => {
  const errors = err?.response?.data
  if (errors) {
    Object.keys(errors).map((key) => {
      toast((t) => (
        <ToastContent
          t={t}
          name="Error While Updating Invoice"
          icon={<X size={14} />}
          color="danger"
          msg={
            errors?.msg ||
            errors?.detail ||
            errors[key] ||
            errors?.non_field_errors?.[0]
          }
        />
      ))
    })
  }
}

// ** Get All Client Billings List
export const getAllClientDocumentsAction = createAsyncThunk(
  'document/getAllClientDocuments',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await useJwt.getAllClientDocuments()
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** Get All Client Billings List
export const getClientDocumentsAction = createAsyncThunk(
  'document/getClientDocuments',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getClientDocuments(id)
      return response?.data
    } catch (err) {
      fieldErrors(err)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Register Client Documents
export const registerClientDocumentsAction = createAsyncThunk(
  'document/registerClientDocuments',
  async ({ data, callback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.registerClientDocuments(data)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Client Document Upload Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      dispatch(getAllClientDocumentsAction())
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Register Client Documents'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Update Client Documents
export const updateClientDocumentsAction = createAsyncThunk(
  'document/updateClientDocuments',
  async ({ data, id, callback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.updateClientDocuments(data, id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Client Document Updated Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      callback()
      // dispatch(getAllClientDocumentsAction())
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Register Client Documents'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)

// ** Delete Client Documents
export const deleteClientDocumentsAction = createAsyncThunk(
  'document/deleteClientDocuments',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await useJwt.deleteClientDocuments(id)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Client Document Deleted Successfully'}
          icon={<Check size={14} />}
          color="success"
          msg={response?.data?.message}
        />
      ))
      dispatch(getAllClientDocumentsAction())
      return response?.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Client Documents'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
